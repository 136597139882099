// import { getUserInfo } from "../../api/user.js"

const state = {
  userInfo: '',
  isStudent:false,
  isLoading:false,
  token:'',
  aijyToken:'',
  homePage_data:{},
  isLogin:false,
  loginName: '',
  userId:''
}

const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo
  },
  setIsStudent(state, isStudent) {
    state.isStudent = isStudent
  },
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setToken(state, token) {
    state.token = token
  },
  setAijyToken(state, token) {
    state.aijyToken = token
  },
  setLoginName(state, loginName) {
    state.loginName = loginName
  },
  setHomePage_data(state, data) {
    state.homePage_data = data
  },
  setIsLogin(state, data) {
    state.isLogin = data
  },
  setUserId(state, data) {
    state.userId = data
  },
}

const actions = {
  // async getUserInfo({ commit, state }) {
  //   if (!state.userInfo) {
  //     const res = await getUserInfo()
  //     commit("setUserInfo", res)
  //     return res
  //   } else {
  //     return state.userInfo
  //   }
  // },
}

export default {
  namespace: true,
  state,
  mutations,
  actions
}